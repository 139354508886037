/*
Este middleware está destinado para ejecutar scripts u obtener data esencial para el funcionamiento de la aplicación.
*/

export default defineNuxtRouteMiddleware(async (to) => {
	const { $feature, $config } = useNuxtApp()

	// Guardar utm en cookie
	const utmParams = filterUtmFromObject(to.query)
	if (Object.keys(utmParams).length) {
		const utmCookie = useCookie($config.public.utm.cookie_name, {
			domain: $config.public.utm.cookie_domain,
			maxAge: 60 * 60 * 24 * 182,
		})
		utmCookie.value = utmParams
	}

	// Si no esta inicializado los feature flags
	if (!$feature.ready) {
		await $feature.loadFeatures({ autoRefresh: true })
		return
	}

	// Refrescar los feature flags
	await $feature.refreshFeatures()
})
