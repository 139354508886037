import { default as billing3QtXUGy6juMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/account/index/billing.vue?macro=true";
import { default as companyjkknQv4tf0Meta } from "/codebuild/output/src621131546/src/frontend-hire/pages/account/index/company.vue?macro=true";
import { default as plansqyH3IAhaNMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/account/index/plan.vue?macro=true";
import { default as indexuLPqSWHXxfMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/account/index.vue?macro=true";
import { default as callbackTHcnbeMcxsMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/auth/callback.vue?macro=true";
import { default as _91token_93LWuZXJJRamMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/auth/reset-password/[token].vue?macro=true";
import { default as indexyuSL3Oj6FQMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/auth/reset-password/index.vue?macro=true";
import { default as indexZlmIR5qSv8Meta } from "/codebuild/output/src621131546/src/frontend-hire/pages/candidates/[idcandidate]/index.vue?macro=true";
import { default as best_45by_45roleAjSAgKhua1Meta } from "/codebuild/output/src621131546/src/frontend-hire/pages/candidates/best-by-role.vue?macro=true";
import { default as indexsCMNYw1JobMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/candidates/index.vue?macro=true";
import { default as scheduledwADFKcDexKMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/demo/scheduled.vue?macro=true";
import { default as indexqnuWHsb0TSMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/index.vue?macro=true";
import { default as login9Sy1C4F4pDMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/login.vue?macro=true";
import { default as logoutGsWLXwRjIuMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/logout.vue?macro=true";
import { default as indexd094uHrUz5Meta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offer/create/index.vue?macro=true";
import { default as createNLaBPmU4JtMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offer/create.vue?macro=true";
import { default as importjuIFMAbciFMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offer/import.vue?macro=true";
import { default as detailsNFJqx4qbomMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue?macro=true";
import { default as indexnZhm94pz3nMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue?macro=true";
import { default as technical_45requirementsqbrFk9N8WRMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue?macro=true";
import { default as editbIPXv1iVUbMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit.vue?macro=true";
import { default as detailsgv7CLXNqRyMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/details.vue?macro=true";
import { default as discardedjKfI6TlTq7Meta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue?macro=true";
import { default as indexrvkEPFZCncMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/index.vue?macro=true";
import { default as pipelineA4IYtW6H2tMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue?macro=true";
import { default as searchMODynj6Pr8Meta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/search.vue?macro=true";
import { default as indexKR23XKxbZEMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index.vue?macro=true";
import { default as password_45resetoazR21TIVfMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/password-reset.vue?macro=true";
import { default as indexdzepXO0pzuMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/register/index.vue?macro=true";
import { default as password_45setupRshFR4fHLTMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/register/password-setup.vue?macro=true";
import { default as profile_45setupk7xz885cAQMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/register/profile-setup.vue?macro=true";
import { default as buyMXaqnGOzduMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/subscription/buy.vue?macro=true";
import { default as payment_45methodo9GoW57n3RMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/subscription/payment-method.vue?macro=true";
import { default as successful1hWgrhrxy4Meta } from "/codebuild/output/src621131546/src/frontend-hire/pages/subscription/successful.vue?macro=true";
import { default as updatecDfYmgy3EFMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/subscription/update.vue?macro=true";
import { default as index8d65CP4VNcMeta } from "/codebuild/output/src621131546/src/frontend-hire/pages/users/index.vue?macro=true";
import { default as component_45stub7thk4PR3dXMeta } from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub7thk4PR3dX } from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account___es",
    path: "/account",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/account/index.vue"),
    children: [
  {
    name: "account-index-billing___es",
    path: "billing",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/account/index/billing.vue")
  },
  {
    name: "account-index-company___es",
    path: "company",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/account/index/company.vue")
  },
  {
    name: "account-index-plan___es",
    path: "plan",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/account/index/plan.vue")
  }
]
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/account/index.vue"),
    children: [
  {
    name: "account-index-billing___en",
    path: "billing",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/account/index/billing.vue")
  },
  {
    name: "account-index-company___en",
    path: "company",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/account/index/company.vue")
  },
  {
    name: "account-index-plan___en",
    path: "plan",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/account/index/plan.vue")
  }
]
  },
  {
    name: "auth-callback___es",
    path: "/auth/callback",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/auth/callback.vue")
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/auth/callback.vue")
  },
  {
    name: "auth-reset-password-token___es",
    path: "/auth/reset-password/:token()",
    meta: _91token_93LWuZXJJRamMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password-token___en",
    path: "/en/auth/reset-password/:token()",
    meta: _91token_93LWuZXJJRamMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password___es",
    path: "/auth/reset-password",
    meta: indexyuSL3Oj6FQMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/auth/reset-password/index.vue")
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    meta: indexyuSL3Oj6FQMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/auth/reset-password/index.vue")
  },
  {
    name: "candidates-idcandidate___es",
    path: "/candidates/:idcandidate()",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/candidates/[idcandidate]/index.vue")
  },
  {
    name: "candidates-idcandidate___en",
    path: "/en/candidates/:idcandidate()",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/candidates/[idcandidate]/index.vue")
  },
  {
    name: "candidates-best-by-role___es",
    path: "/candidates/best-by-role",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/candidates/best-by-role.vue")
  },
  {
    name: "candidates-best-by-role___en",
    path: "/en/candidates/best-by-role",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/candidates/best-by-role.vue")
  },
  {
    name: "candidates___es",
    path: "/candidates",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/candidates/index.vue")
  },
  {
    name: "candidates___en",
    path: "/en/candidates",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/candidates/index.vue")
  },
  {
    name: "demo-scheduled___es",
    path: "/demo/scheduled",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/demo/scheduled.vue")
  },
  {
    name: "demo-scheduled___en",
    path: "/en/demo/scheduled",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/demo/scheduled.vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/index.vue")
  },
  {
    name: "login___es",
    path: "/login",
    meta: login9Sy1C4F4pDMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: login9Sy1C4F4pDMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/login.vue")
  },
  {
    name: "logout___es",
    path: "/logout",
    meta: logoutGsWLXwRjIuMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/logout.vue")
  },
  {
    name: "logout___en",
    path: "/en/logout",
    meta: logoutGsWLXwRjIuMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/logout.vue")
  },
  {
    name: createNLaBPmU4JtMeta?.name,
    path: "/offer/create",
    meta: createNLaBPmU4JtMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offer/create.vue"),
    children: [
  {
    name: "offer-create___es",
    path: "",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offer/create/index.vue")
  }
]
  },
  {
    name: createNLaBPmU4JtMeta?.name,
    path: "/en/offer/create",
    meta: createNLaBPmU4JtMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offer/create.vue"),
    children: [
  {
    name: "offer-create___en",
    path: "",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offer/create/index.vue")
  }
]
  },
  {
    name: "offer-import___es",
    path: "/offer/import",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offer/import.vue")
  },
  {
    name: "offer-import___en",
    path: "/en/offer/import",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offer/import.vue")
  },
  {
    name: editbIPXv1iVUbMeta?.name,
    path: "/offers/:idoffer()/edit",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit.vue"),
    children: [
  {
    name: "offers-idoffer-edit-details___es",
    path: "details",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue")
  },
  {
    name: "offers-idoffer-edit___es",
    path: "",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue")
  },
  {
    name: "offers-idoffer-edit-technical-requirements___es",
    path: "technical-requirements",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue")
  }
]
  },
  {
    name: editbIPXv1iVUbMeta?.name,
    path: "/en/offers/:idoffer()/edit",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit.vue"),
    children: [
  {
    name: "offers-idoffer-edit-details___en",
    path: "details",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue")
  },
  {
    name: "offers-idoffer-edit___en",
    path: "",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue")
  },
  {
    name: "offers-idoffer-edit-technical-requirements___en",
    path: "technical-requirements",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue")
  }
]
  },
  {
    name: indexKR23XKxbZEMeta?.name,
    path: "/offers/:idoffer()",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index.vue"),
    children: [
  {
    name: "offers-idoffer-index-details___es",
    path: "details",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/details.vue")
  },
  {
    name: "offers-idoffer-index-discarded___es",
    path: "discarded",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue")
  },
  {
    name: "offers-idoffer-index___es",
    path: "",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/index.vue")
  },
  {
    name: "offers-idoffer-index-pipeline___es",
    path: "pipeline",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue")
  },
  {
    name: "offers-idoffer-index-search___es",
    path: "search",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/search.vue")
  }
]
  },
  {
    name: indexKR23XKxbZEMeta?.name,
    path: "/en/offers/:idoffer()",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index.vue"),
    children: [
  {
    name: "offers-idoffer-index-details___en",
    path: "details",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/details.vue")
  },
  {
    name: "offers-idoffer-index-discarded___en",
    path: "discarded",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue")
  },
  {
    name: "offers-idoffer-index___en",
    path: "",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/index.vue")
  },
  {
    name: "offers-idoffer-index-pipeline___en",
    path: "pipeline",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue")
  },
  {
    name: "offers-idoffer-index-search___en",
    path: "search",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/offers/[idoffer]/index/search.vue")
  }
]
  },
  {
    name: "password-reset___es",
    path: "/password-reset",
    meta: password_45resetoazR21TIVfMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/password-reset.vue")
  },
  {
    name: "password-reset___en",
    path: "/en/password-reset",
    meta: password_45resetoazR21TIVfMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/password-reset.vue")
  },
  {
    name: "register___es",
    path: "/register",
    meta: indexdzepXO0pzuMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/register/index.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: indexdzepXO0pzuMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/register/index.vue")
  },
  {
    name: "register-password-setup___es",
    path: "/register/password-setup",
    meta: password_45setupRshFR4fHLTMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/register/password-setup.vue")
  },
  {
    name: "register-password-setup___en",
    path: "/en/register/password-setup",
    meta: password_45setupRshFR4fHLTMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/register/password-setup.vue")
  },
  {
    name: "register-profile-setup___es",
    path: "/register/profile-setup",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/register/profile-setup.vue")
  },
  {
    name: "register-profile-setup___en",
    path: "/en/register/profile-setup",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/register/profile-setup.vue")
  },
  {
    name: "subscription-buy___es",
    path: "/subscription/buy",
    meta: buyMXaqnGOzduMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/subscription/buy.vue")
  },
  {
    name: "subscription-buy___en",
    path: "/en/subscription/buy",
    meta: buyMXaqnGOzduMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/subscription/buy.vue")
  },
  {
    name: "subscription-payment-method___es",
    path: "/subscription/payment-method",
    meta: payment_45methodo9GoW57n3RMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/subscription/payment-method.vue")
  },
  {
    name: "subscription-payment-method___en",
    path: "/en/subscription/payment-method",
    meta: payment_45methodo9GoW57n3RMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/subscription/payment-method.vue")
  },
  {
    name: "subscription-successful___es",
    path: "/subscription/successful",
    meta: successful1hWgrhrxy4Meta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/subscription/successful.vue")
  },
  {
    name: "subscription-successful___en",
    path: "/en/subscription/successful",
    meta: successful1hWgrhrxy4Meta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/subscription/successful.vue")
  },
  {
    name: "subscription-update___es",
    path: "/subscription/update",
    meta: updatecDfYmgy3EFMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/subscription/update.vue")
  },
  {
    name: "subscription-update___en",
    path: "/en/subscription/update",
    meta: updatecDfYmgy3EFMeta || {},
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/subscription/update.vue")
  },
  {
    name: "users___es",
    path: "/users",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/users/index.vue")
  },
  {
    name: "users___en",
    path: "/en/users",
    component: () => import("/codebuild/output/src621131546/src/frontend-hire/pages/users/index.vue")
  },
  {
    name: component_45stub7thk4PR3dXMeta?.name,
    path: "/profile",
    component: component_45stub7thk4PR3dX
  },
  {
    name: component_45stub7thk4PR3dXMeta?.name,
    path: "/comprar-suscripcion",
    component: component_45stub7thk4PR3dX
  },
  {
    name: component_45stub7thk4PR3dXMeta?.name,
    path: "/offers",
    component: component_45stub7thk4PR3dX
  }
]