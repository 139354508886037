
// @ts-nocheck
import locale__codebuild_output_src621131546_src_frontend_hire_lang_es_ts from "../lang/es.ts?hash=9104c949&locale=es";
import locale__codebuild_output_src621131546_src_frontend_hire_lang_en_ts from "../lang/en.ts?hash=1d4a7f4b&locale=en";


export const localeCodes =  [
  "es",
  "en"
]

export const localeLoaders = {
  "es": [{ key: "../lang/es.ts", load: () => Promise.resolve(locale__codebuild_output_src621131546_src_frontend_hire_lang_es_ts), cache: false }],
  "en": [{ key: "../lang/en.ts", load: () => Promise.resolve(locale__codebuild_output_src621131546_src_frontend_hire_lang_en_ts), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "jsTsFormatResource": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "emoji": "🇪🇸",
      "icon": "flag:es-1x1",
      "name": "Español",
      "code": "es",
      "files": [
        "/codebuild/output/src621131546/src/frontend-hire/lang/es.ts"
      ]
    },
    {
      "emoji": "🇺🇸",
      "icon": "flag:um-1x1",
      "name": "English",
      "code": "en",
      "files": [
        "/codebuild/output/src621131546/src/frontend-hire/lang/en.ts"
      ]
    }
  ],
  "defaultLocale": "es",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "lang",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "autoTranslateConfig": {
    "enable_cache": true,
    "path_file": "/public/languages/",
    "services": {
      "default": "openAI",
      "openAI": {
        "key": "sk-W-WBANMHjjFScfvITNBGgVfLeYlQaDlB1W98VryMuQT3BlbkFJNffBO7Y6BY_GGvbpUw8iQz9Hd3T4aL2QgNCH8qXS0A"
      },
      "googleTranslate": {}
    },
    "compilation": {
      "strictMessage": false
    }
  },
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "emoji": "🇪🇸",
    "icon": "flag:es-1x1",
    "name": "Español",
    "code": "es",
    "files": [
      {
        "path": "/codebuild/output/src621131546/src/frontend-hire/lang/es.ts"
      }
    ]
  },
  {
    "emoji": "🇺🇸",
    "icon": "flag:um-1x1",
    "name": "English",
    "code": "en",
    "files": [
      {
        "path": "/codebuild/output/src621131546/src/frontend-hire/lang/en.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
