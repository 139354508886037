export default defineAppConfig({
	ui: {
		// Config Global https://github.com/nuxt/ui/blob/dev/src/runtime/ui.config/index.ts

		// Colors
		primary: 'purple',
		gray: 'slate',

		// Components
		container: {
			padding: 'p-4 sm:p-6 lg:p-8',
			constrained: 'container w-full max-w-screen-4xl 5xl:max-w-screen-5xl',
		},
		button: {
			default: {
				size: 'md',
				loadingIcon: 'border-current border-2 rounded-full border-b-transparent opacity-70',
			},
			icon: {
				size: {
					'2xs': 'h-4 w-4',
					xs: 'h-4 w-4',
					sm: 'h-4 w-4',
					md: 'h-4 w-4',
					lg: 'h-4 w-4',
					xl: 'h-4 w-4',
				},
			},
		},
		dropdown: {
			item: {
				padding: 'p-2',
				icon: {
					base: 'h-4.5 w-4.5',
				},
			},
		},
		card: {
			rounded: 'rounded-2xl',
			shadow: '',
			ring: '', // ring-1 ring-gray-200 dark:ring-gray-800
		},
		badge: {
			rounded: 'rounded-full',
		},
		tooltip: {
			background: 'bg-gray-900 bg-opacity-80',
			color: 'text-white',
			base: 'px-3 py-2',
		},
		checkbox: {
			wrapper: 'mb-1.5',
			inner: 'ms-0',
			label: 'pl-3',
		},
		modal: {
			padding: 'p-4 sm:p-4',
			overlay: {
				background: 'bg-neutral-700 bg-opacity-50',
			},
		},
		slideover: {
			overlay: {
				background: 'bg-neutral-700 bg-opacity-50',
			},
		},
		alert: {
			description: 'mt-[0.15rem]',
		},
		pagination: {
			wrapper: 'space-x-0 gap-1 ',
			base: 'min-h-8 min-w-8 justify-center',
			rounded: 'rounded-lg first:rounded-lg last:rounded-lg',
			default: {
				firstButton: {
					variant: 'ghost',
					size: 'xl',
				},
				lastButton: {
					variant: 'ghost',
					size: 'xl',
				},
				prevButton: {
					variant: 'ghost',
					size: 'xl',
				},
				nextButton: {
					variant: 'ghost',
					size: 'xl',
				},
			},
		},
		skeleton: {
			base: 'h-[1em]',
			background: 'bg-neutral-100 bg-opacity-50',
		},
		avatar: {
			rounded: 'object-cover',
		},
	},
})
