import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/es'
import 'dayjs/locale/en'

dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.locale('es')

export const getDaysRemaining = (expiryDate) => {
	if (!expiryDate) return 0
	const now = dayjs()
	const expiry = dayjs(expiryDate)

	return expiry.diff(now, 'day')
}

export default dayjs
