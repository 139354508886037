import revive_payload_client_ndPWfmLKG3 from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wNhdXFN1MQ from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QxSll6dED3 from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kxImW9ahHH from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_IWyAXLBcRM from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_rl8xHiatlV from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Pbyd8gQYuw from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_EzIQGIe80m from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src621131546/src/frontend-hire/.nuxt/components.plugin.mjs";
import prefetch_client_se1Zs9oqHX from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_KKue0Bx6JL from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/@nuxt+ui@2.18.7_axios@1.7.7_magicast@0.3.5_rollup@4.24.0_sortablejs@1.14.0_vite@5.4.9_@types+_ei2oyi2n2kbhfowvxdowcedtb4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_4so1ZSvaqS from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/@nuxt+ui@2.18.7_axios@1.7.7_magicast@0.3.5_rollup@4.24.0_sortablejs@1.14.0_vite@5.4.9_@types+_ei2oyi2n2kbhfowvxdowcedtb4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_tZRoOF11AT from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/@nuxt+ui@2.18.7_axios@1.7.7_magicast@0.3.5_rollup@4.24.0_sortablejs@1.14.0_vite@5.4.9_@types+_ei2oyi2n2kbhfowvxdowcedtb4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_AA5wjsUaZH from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_u2eUcyDfNn from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.7_terser@5.36.0__vue@3.5.12_typescript@5.6.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_jVLfBSm2Og from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ipqiXPJvN0 from "/codebuild/output/src621131546/src/frontend-hire/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_axios_trfC49DPAq from "/codebuild/output/src621131546/src/frontend-hire/plugins/01.axios.ts";
import _02_auth_kllCetJ9Sb from "/codebuild/output/src621131546/src/frontend-hire/plugins/02.auth.ts";
import _03_requestRepository_FW7ZZoIDXJ from "/codebuild/output/src621131546/src/frontend-hire/plugins/03.requestRepository.ts";
import _04_featureFlags_a1Cth6iBUN from "/codebuild/output/src621131546/src/frontend-hire/plugins/04.featureFlags.ts";
import _05_tracking_client_geGWrgXAYH from "/codebuild/output/src621131546/src/frontend-hire/plugins/05.tracking.client.ts";
import _06_dates_KRCiNSk3xi from "/codebuild/output/src621131546/src/frontend-hire/plugins/06.dates.ts";
import _07_subscription_PLVAVyZUaD from "/codebuild/output/src621131546/src/frontend-hire/plugins/07.subscription.ts";
import datadog_client_cfAAU11B0P from "/codebuild/output/src621131546/src/frontend-hire/plugins/datadog.client.ts";
import maska_UHaKf2z1iQ from "/codebuild/output/src621131546/src/frontend-hire/plugins/maska.ts";
import socket_xW42qVsBqW from "/codebuild/output/src621131546/src/frontend-hire/plugins/socket.ts";
import vCalendar_jfuLUQ1Ymq from "/codebuild/output/src621131546/src/frontend-hire/plugins/vCalendar.ts";
import vueToast_client_LXSTX0Nibh from "/codebuild/output/src621131546/src/frontend-hire/plugins/vueToast.client.ts";
import vueTooltip_7qaORb5I8e from "/codebuild/output/src621131546/src/frontend-hire/plugins/vueTooltip.ts";
export default [
  revive_payload_client_ndPWfmLKG3,
  unhead_wNhdXFN1MQ,
  router_QxSll6dED3,
  payload_client_kxImW9ahHH,
  navigation_repaint_client_IWyAXLBcRM,
  check_outdated_build_client_rl8xHiatlV,
  chunk_reload_client_Pbyd8gQYuw,
  plugin_vue3_EzIQGIe80m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_se1Zs9oqHX,
  slideovers_KKue0Bx6JL,
  modals_4so1ZSvaqS,
  colors_tZRoOF11AT,
  plugin_client_AA5wjsUaZH,
  plugin_u2eUcyDfNn,
  switch_locale_path_ssr_jVLfBSm2Og,
  i18n_ipqiXPJvN0,
  _01_axios_trfC49DPAq,
  _02_auth_kllCetJ9Sb,
  _03_requestRepository_FW7ZZoIDXJ,
  _04_featureFlags_a1Cth6iBUN,
  _05_tracking_client_geGWrgXAYH,
  _06_dates_KRCiNSk3xi,
  _07_subscription_PLVAVyZUaD,
  datadog_client_cfAAU11B0P,
  maska_UHaKf2z1iQ,
  socket_xW42qVsBqW,
  vCalendar_jfuLUQ1Ymq,
  vueToast_client_LXSTX0Nibh,
  vueTooltip_7qaORb5I8e
]