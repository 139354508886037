/*
Este middleware destinado a la autenticación de la aplicación, toda la seguridad de rutas públicas, privadas y guest.
*/

import { datadogLogs } from '@datadog/browser-logs'

export default defineNuxtRouteMiddleware(async (to) => {
	const { $localePath, $config, $auth } = useNuxtApp()
	const { path, meta, fullPath } = to

	if (path === $localePath('/logout')) return

	const authCookieToken = useCookie($config.public.auth.name_cookie_token)
	const authCookieType = useCookie($config.public.auth.name_cookie_type)

	// Si no hay token
	if (!authCookieToken.value || authCookieType.value !== 'hire') {
		if (meta.auth !== 'guest') {
			const cookieRedirect = useCookie($config.public.auth.name_cookie_redirect)
			cookieRedirect.value = fullPath
		}

		if (meta.auth === 'guest') return
		return navigateTo($localePath(`/login`))
	}

	// Guardamos el token en el store
	$auth.ADD_TOKEN(authCookieToken.value, authCookieType.value)

	if ($auth.user === null) {
		const { success } = await $auth.fetchMe()

		// Si falla mandamos al login
		if (!success) {
			$auth.REMOVE_TOKEN()
			if (datadogLogs.getUser()) {
				datadogLogs.clearUser()
			}
			return navigateTo($localePath(`/login`))
		}
	}

	// Si son rutas guest redirigimos al home
	if (meta.auth === 'guest') {
		return navigateTo($localePath(`/`))
	}
})
