import { datadogLogs } from '@datadog/browser-logs'
import { gbAttributes } from '~/plugins/04.featureFlags'
import type { AuthUser } from '~/types/auth'

interface Auth extends AuthUser {
	loggedIn: boolean
}

const initialState: Auth = {
	user: null,
	loggedIn: false,
}

export const useAuthStore = defineStore('auth', {
	state: () => ({ ...initialState }),
	getters: {
		hasPreference: (state) => (name: string) => {
			if (!state.user) return false
			return state.user?.preferences?.[name] ?? false
		},
		getAvatar: (state) => {
			if (!state.user) return null
			const { avatar, business } = state.user

			if (avatar) return avatar
			if (business.logo_path) return business.logo_path

			return null
		},
		getUserName: (state) => {
			if (!state.user) return null
			if (state.user.name) return state.user.name
			return state.user.business.name
		},
	},
	actions: {
		ADD_TOKEN(token, type = 'hire') {
			const { $fetchHire, $config } = useNuxtApp()

			const authCookieToken = useCookie($config.public.auth.name_cookie_token, {
				domain: $config.public.auth.name_cookie_domain,
				maxAge: 60 * 60 * 24 * 365, // un año de expiración
			})

			const authCookieType = useCookie($config.public.auth.name_cookie_type, {
				domain: $config.public.auth.name_cookie_domain,
				maxAge: 60 * 60 * 24 * 365, // un año de expiración
			})

			// Activar la sesión
			this.loggedIn = true

			// Agregar token a la cookie
			authCookieToken.value = token
			authCookieType.value = type

			// Agregar token a las peticiones
			$fetchHire.defaults.headers.common.Authorization = `Bearer ${token}`
		},
		REMOVE_TOKEN() {
			const { $fetchHire, $config, $feature } = useNuxtApp()

			const authCookieToken = useCookie($config.public.auth.name_cookie_token, {
				domain: $config.public.auth.name_cookie_domain,
			})

			const authCookieType = useCookie($config.public.auth.name_cookie_type, {
				domain: $config.public.auth.name_cookie_domain,
			})

			// Eliminar token de la cookie
			authCookieToken.value = null
			authCookieType.value = null

			// Resetear estado
			this.loggedIn = false
			this.user = null

			// Eliminar token de las peticiones
			$fetchHire.defaults.headers.common.Authorization = null

			// Resetear usuario de FF
			$feature.setAttributes({})
			$feature.refreshFeatures()
		},
		async login(params) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.hire.auth.postLogin(params)

			if (response.success) {
				this.ADD_TOKEN(response.result.access_token)
			}

			return response
		},
		async fetchMe() {
			const { $repository, $feature, $subscription } = useNuxtApp()

			const response = await $repository.v3.hire.auth.getMe()

			if (response.success) {
				this.user = response.result
				await $subscription.fetchCurrentSubscription()
				$feature.setAttributes(gbAttributes(response.result))
				$feature.refreshFeatures()
				datadogLogs.setUser({
					id: response.result.id,
					email: response.result.email,
					name: response.result.name,
				})
			}

			return response
		},
	},
})
